import React from 'react'

export const Aboutus = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="about-us">
                        <h2 className='text-center py-3'><b className='text-theme'>About</b> us</h2>
                        <img src="./images/about-img.jpg" alt="" />
                        <h5>"Arts and Crafts in all its forms are an expression of feelings, innocence, creativity, intelligence and years of dedication put into mastering the skill."</h5>
                       <p>
                            Founded with a vision to promote a "Vocal for Local" campaign and to transform and scale up the entire structure of the business for our people deeply involved with Arts, Crafts, and similar craftsmanship by adding value and scale with technology, logistics, and our marketing tools. The Indo-craft world platform aims to realize the hidden potential that exists for the craftsmen and women of India who have kept this knowledge and skills alive for generations after generations.<br /> <br />

                            We envision our Arts & crafts as the soul of Indian culture, traditions, values, and living skills. In our collective endeavor to bring forth this platform, we expect that the world would see and experience the living legacy and heritage of India and to own some of them as a treasure and to use a lot of them as a part of daily life. The centuries-old crafts in their different forms have their own simplicity, uniqueness, mesmerizing beauty, and brilliance, displaying the mastered skills that exist in some of the remotest corners of India. The products made by artisans have the power to lighten up our surroundings and fill them up with positivity; at the same time, they offer us great utility value, comfort, and satisfaction at a very reasonable cost. Art and Craft in all its forms are the most synchronized and balanced manifestations of the deep emotional bond we share with our nature, environment, and ecosystem, reflecting the philosophy of co-existence and harmony with our surroundings.<br /> <br />

                            Indocraftworld platform has multiple dimensions; besides bringing in huge opportunities to the artisans and numerous choices to our buyers, it also presents a source of information and learning for our younger generation who can visit and see the vastness of our rich cultural heritage on our platform. It leverages technology to optimize the opportunities, which would connect the entire supply chain from the remotest corner and bring them to the fore so that our art and craft products get wider acknowledgment with better demand and, in turn, bring much-needed prosperity to the lives involved.

                            Our e-commerce platform is also focussing on spreading its operations across all categories to assimilate and connect all stakeholders for an enhanced buying and selling experience. We have mapped the challenging areas faced by our micro and small enterprises working in this sector. We have devised this one-stop solution platform with various features, e.g., a state-of-the-art AI-enabled photography app for the products at a minimum cost, customized packaging solutions, integrated logistics solutions, and a very efficient system for on-time settlement and transfer of payment to our sellers. Our marketing team has been working on various strategies to scale up demand for our craftsmen so that both our sellers and buyers can benefit when a high-volume demand creates a better pricing proposition. <br /> <br />

                            This platform acts as a catalyst to enable transparency, accessibility, and affordability and provides national and international reach to all our products produced by our sellers at a competitive cost with complete payment security and complete transparency. Through a seamless process, it enables the buyer to select a product and place an order with sellers, who then pack it, to ensure all safety and security before dispatching. The platform has enabled logistics focused on B2C and B2B trade, built on strong technology and operational support for fulfillment and delivery service through our trusted and verified Logistics partners.<br /> <br />

                            <a href='https://indocraftworld.com/' style={{ color: 'blue' }}>Indocraftworld.com</a> is headquartered in New Delhi and is in the process of opening offices in all leading metros and major cities across India.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}
