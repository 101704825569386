import { Visibility, VisibilityOff } from '@mui/icons-material'
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { loginUser, loginUserWithNumber, setUser } from '../../features/authSlice'
import { GetOverAllCountAPI } from '../../features/getCartAndWishlistCountSlice'
import { AlertBox, ApiCall, setFocus } from '../Common/service'
import { LoginSvg } from './LoginSvg'
import './login.css'
import Loader from '../Common/Loader'




export const Login = (props) => {
    const { image = true, } = props;
    const [loginWithOTP, setLoginWithOTP] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const from = location.state?.from?.pathname || '/';
    const [isDisableOTPInput, setIsDisableOTPInput] = useState(true);
    const [formData, setFormData] = useState({
        userCode: "",
        password: "",
        isRemember: false,
    });
    const [otpLoader, setOtpLoader] = useState(false)
    const [otpSent, setOtpSent] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [otpFormData, setotpFormData] = useState({
        mobile: "",
        otp: "",
       

    })
    const handleClear = () => {
        setotpFormData({
            mobile: "",
            otp: "",
        });

    }

    const dispatch = useDispatch()
    const { uniqueUserId, isAuthenticated } = useSelector((state) => state.authData)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    // Handle Login 
    const HandleLoggedIn = async () => {
        if (
            !/^\d{10}$/.test(formData.userCode.replace(/-/g, '')) &&
            !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.userCode) &&
            formData.userCode === ""

        ) {
            toast.error('Please Enter a  Email / Mobile No.');
            return;
        }
        const loginSubEquipment = {
            ...formData,
            CartToken: uniqueUserId ? uniqueUserId : uniqueUserId
        }

        // dispatch(loginUser(loginSubEquipment))
        await dispatch(loginUser(loginSubEquipment))
            .then(async (response) => {   
                if (response.payload.status === 'SUCCESS') {
                    dispatch(GetOverAllCountAPI())
                    toast.success(response.payload.message)
                    navigate(from, { replace: true })
                   // navigate('/')
                } else if (response.payload.status === "ERROR") {
                    await localStorage.removeItem('authUser');
                    
                    toast.error(response.payload.message)
                }
            })
            .catch((error) => {
                console.error('Error logging in:', error);
            });  
    };

    // Handle OTP Sent
    const HandleOTPSent = async () => {
        //setOtpSent(true);
        setOtpLoader(true)
        let data = await ApiCall(`/api/Web/SendOTP`, { ...otpFormData, Type: "login" })
        if (data.status === 'SUCCESS') {  
        setOtpLoader(false)

            AlertBox(data.status, data.message)
            await setTimer(30);
            await setIsDisabled(true);
            await setIsDisableOTPInput(false)
            setOtpSent(true);
        } else if (data.status === 'ERROR') {
            setOtpLoader(false)

            await AlertBox(data.status, data.message, data.focus);
            await setIsDisableOTPInput(true)
            await setUser({});
        }
    };
    // Handle OTP LOGIN
    const HandleOTPLoggedIn = async () => {
        const loginSubEquipment = {
            ...otpFormData,
            CartToken: uniqueUserId ? uniqueUserId : uniqueUserId
        }
        await dispatch(loginUserWithNumber(loginSubEquipment))
            .then(async (response) => {
                if (response.payload.status === "SUCCESS") {
                    dispatch(GetOverAllCountAPI())
                    toast.success(response.payload.message)
                    navigate(from, { replace: true })
                   // navigate('/')
                } else if (response.payload.status === 'ERROR') {
                    toast.error(response.payload.message)                     
                        await localStorage.removeItem('authUser');
                    setotpFormData({ ...otpFormData, otp: "" })
                    toast.error(response.payload.message)
                } else if (response.payload.status === "OTP Exipred Please Re-Send OTP") {
                        setOtpSent(false);
                }
            })       
    };

    useEffect(() => {
        if (isAuthenticated) navigate('/')
    }, [])

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else if (timer === 0 && isDisabled) {
            setIsDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer, isDisabled]);

    if (otpLoader) {
        return <Loader />
    }

    return (
        <>           
         

               <section  className={'bg-light login_now'}>
                    <div className={"container "}>
                        <div className={image ? "login rounded" : "login rounded h-100"}>
                            <div className={image ? "row" : "row h-100"}>
                                {image && <div className="col-md-6 p-0 login-img justify-content-center align-items-center flex-column d-none d-md-flex">
                                    <LoginSvg />
                                    <h6 className='text-white mb-2 mt-4'>Welcome Back!</h6>
                                    <h5 className='text-white'>The World Best Craft Products.</h5>
                                </div>}
                                <div className={image ? "col-md-6 p-0 d-flex flex-column justify-content-center" : "col-md-12 p-0 d-flex flex-column justify-content-between"}>
                                    <div className="login-form">
                                        {!loginWithOTP ? (
                                            <>
                                                <h5>Log In Your Account</h5>
                                                <div className='my-3'>
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        id="txtUserCode"
                                                        label="Email / Mobile No"
                                                        variant="standard"
                                                        type="text"
                                                        required
                                                        value={formData.userCode}
                                                        onChange={(e) => setFormData({ ...formData, userCode: e.target.value })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                if (formData.userCode.trim() === '') {
                                                                    e.preventDefault();
                                                                    toast.error('Please Enter Email/Mobile No.');
                                                                } else {
                                                                    setFocus("txtPassword");
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className='mb-3'>
                                                    <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                                        <InputLabel htmlFor="txtPassword">Password</InputLabel>
                                                        <Input
                                                            id="txtPassword"
                                                            type={showPassword ? 'text' : 'password'}
                                                            value={formData.password}
                                                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    if (formData.password.trim() === '') {
                                                                        e.preventDefault();
                                                                        toast.error('Please Enter Your Password');
                                                                    } else {
                                                                        setFocus("txtIsRemember");
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                </div>
                                                <div className="form-check mt-3">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={formData.isRemember}
                                                        onChange={(e) => setFormData({ ...formData, isRemember: e.target.checked })}
                                                        id="txtIsRemember"
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { HandleLoggedIn() };
                                                        }}
                                                    />
                                                    <label className="form-check-label" for='txtIsRemember'> Remember me? </label>
                                                </div>
                                                <div className='mt-3'>
                                                    <button onClick={() => HandleLoggedIn()} className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'>{image ? 'Login' : "Continue"}</button>
                                                </div>
                                                <div className='mt-3'>
                                                    <button onClick={() => setLoginWithOTP(true)} className='bg-primary text-white py-2 w-100 border-0 rounded mybtn'>Login By OTP</button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <h5>Log In Your Account By OTP</h5>
                                                <div className='my-3'>
                                                    <TextField
                                                        sx={{ width: '100%' }}
                                                        id="txtMobileNo"
                                                        label="Email / Mobile Number"
                                                        variant="standard"
                                                        type="text"
                                                        required
                                                        value={otpFormData.mobile}
                                                        onChange={(e) => setotpFormData({ ...otpFormData, mobile: e.target.value })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') { setFocus("txtPassword") };
                                                        }}
                                                    />
                                                </div>
                                                {otpSent && (
                                                    <div className='my-3'>
                                                        <label>Enter 6 Digit OTP</label>
                                                        <MuiOtpInput
                                                            value={otpFormData.otp}
                                                            length={6}
                                                            onChange={(otp) => setotpFormData({ ...otpFormData, otp: otp })}
                                                        />
                                                        {/*<p className='my-3 d-flex' >*/}
                                                        {/*    Didn't receive an OTP?*/}
                                                        {/*    <h6 onClick={HandleOTPSent} style={{ fontWeight: 'bold', cursor: "pointer", marginLeft: "7px" }}>Resend</h6>*/}
                                                        {/*</p>*/}
                                                        <p style={{}} className='my-3 d-flex'>
                                                            Didn't receive an OTP?
                                                            <button
                                                                type="button"
                                                                onClick={HandleOTPSent}
                                                                style={{
                                                                    background: 'none',
                                                                    border: 'none',
                                                                    color: '#007bff',
                                                                    fontWeight: 'bold',
                                                                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                                    marginLeft: '7px',
                                                                    opacity: isDisabled ? 0.5 : 1,
                                                                }}
                                                                disabled={isDisabled}
                                                            >
                                                                {isDisabled ? `Resend (${timer}s)` : 'Resend'}
                                                            </button>
                                                        </p>
                                                    </div>

                                                )}
                                                <div className='mt-3'>

                                                    <button
                                                        className={`bg-primary text-white py-2 w-100 border-0 rounded mybtn mt-3 ${(otpFormData.mobile.length !== 10) ? "disabledBTN" : "mybtn"}`} disabled={otpFormData.mobile.length !== 10} onClick={() => otpSent ? HandleOTPLoggedIn() : HandleOTPSent()}>
                                                        {otpSent ? 'Login' : 'Send OTP'}
                                                    </button>
                                                    <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn mt-3' onClick={() => {
                                                        setLoginWithOTP(false)
                                                        setOtpSent(false);
                                                        setTimer(0);
                                                        handleClear()
                                                    }}>
                                                        Login By Password
                                                    </button>
                                                </div>
                                            </>
                                        )}

                                        <div className="login-footer text-center mt-2">
                                            <p>Don't Have an Account? <Link to='/signup' className='text-primary'>Sign up now</Link></p>
                                            <p>Forgot Password? <Link to='/reset-password' className='text-primary'>Reset</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        
        </>
    )
}
