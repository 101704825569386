import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField, Select, MenuItem, Box, CircularProgress, ListItemIcon, ListItemText } from '@mui/material';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { AlertBox, ApiCall, setFocus } from '../../Common/service';
import './Signup.css';
import { SignupSvg } from './SignupSvg';
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../Common/Loader';

export const Signup = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [otpSentMobile, setOtpSentMobile] = useState(false);
    const [otpSentEmail, setOtpSentEmail] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [timer, setTimer] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [formData, setFormData] = useState({
        customerID: '',
        customerName: '',
        countryCode: '+91',
        mobile: '',
        MobileOTP: "",
        EmailOTP:"",
        emailID: '',
        password: '',
    });
    const [countryCodes, setCountryCodes] = useState([]);
    const { isAuthenticated } = useSelector((state) => state.authData)
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleRegister = async (e) => {
        e.preventDefault();
        if (formData.customerName.trim() === '') {
            toast.error('Please Enter Your Name');
            return;
        }
        if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(formData.emailID)) {
            toast.error('Please Enter a Valid Email Address.');
            return;
        }
        if (formData.password.trim() === '') {
            toast.error('Please Enter Your Password');
            return;
        }
        let data = await ApiCall(`/api/Web/CustomerRegistration`, formData)
        if (data.status === 'SUCCESS') {
            await AlertBox(data.status, data.message)
            await setFormData({
                customerID: '',
                customerName: '',
                countryCode:'',
                mobile: '',
                MobileOTP: "",
                EmailOTP:"",
                emailID: '',
                password: '',
            })
            navigate('/login')
        } else if (data.status === 'ERROR') {
            toast.error(data.message)
            setFocus(data.focus)
        }
    }
    const handleCountryCodeChange = (e) => {
        setFormData({ ...formData, countryCode: e.target.value });
    };
    const HandleOTPSentMobile = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/SendOTP`, { ...formData, Type: "" })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            await setTimer(30);
            await setIsDisabled(true);
            setOtpSentMobile(true);
            setLoading(false)
        } else if (data.status === 'ERROR') {
            await AlertBox(data.status, data.message, data.focus);
            setLoading(false)
        }
        setLoading(false)
    };
    const HandleOTPSentEmail = async () => {
        setLoading(true)
        let data = await ApiCall(`/api/Web/SendMailOTP`, { ...formData })
        if (data.status === 'SUCCESS') {
            AlertBox(data.status, data.message)
            setOtpSentEmail(true);
            setLoading(false)
        } else if (data.status === 'ERROR') {
            await AlertBox(data.status, data.message, data.focus);
            setLoading(false)
        }
        setLoading(false)
    };
    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countries = response.data.map((country) => ({
                name: country.name.common,
                dial_code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ''),
                flag: country.flags.png,
            }));
            setCountryCodes(countries);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching country codes:', error);
            toast.error('Failed to load country codes.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCountryCodes()
    }, [])

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        } else if (timer === 0 && isDisabled) {
            setIsDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer, isDisabled]);


    if (isAuthenticated) {
        navigate('/')
        return;
    }
    if (loading) return <Loader />
    return (
        <>
            <section className={'bg-light login_now'}>
                <div className={"container"}>
                    <div className="login rounded h-100">
                        <div className="row">
                            <div className="col-md-6 p-0 login-img justify-content-center align-items-center flex-column d-none d-md-flex">
                                <SignupSvg />
                                <h6 className='text-white mb-2 mt-4'>We Are Welcome You !</h6>
                                <h5 className='text-white'>The World Best Craft Products.</h5>
                            </div>
                            <div className="col-md-6 p-0 d-flex flex-column justify-content-center">
                                <div className="login-form">
                                    <h5>Create Your Account Here</h5>
                                    <div className='my-3' >
                                        <TextField
                                            sx={{ width: '100%' }}
                                            id="txtName"
                                            label="Your Name"
                                            variant="standard"
                                            type="text"
                                            required
                                            value={formData.customerName}
                                            onChange={(e) => setFormData({ ...formData, customerName: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (formData.customerName.trim() === '') {
                                                        e.preventDefault();
                                                        toast.error('Please Enter Your Name');
                                                    } else {
                                                        setFocus("txtMobileNo");
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='mb-3' >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <FormControl variant="standard" sx={{ minWidth: 120, marginRight: 2,marginTop:2 }}>
                                                <Select
                                                    labelId="country-code-label"
                                                    id="country-code"
                                                    value={formData.countryCode}
                                                    onChange={handleCountryCodeChange}
                                                >
                                                    {loading ? (
                                                        <MenuItem value="">
                                                            <CircularProgress size={24} />
                                                        </MenuItem>
                                                    ) : (
                                                        countryCodes.map((country) => (
                                                            <MenuItem key={country.dial_code} value={country.dial_code}>

                                                                <ListItemText primary={` ${country.dial_code}`} />
                                                                <ListItemIcon>
                                                                    <img src={country.flag} alt={`${country.name} flag`} width="22" height="14" className="mt-2" />
                                                                </ListItemIcon>
                                                            </MenuItem>
                                                        ))
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                sx={{ width: '100%' }}
                                                id="txtMobileNo"
                                                label="Mobile Number"
                                                variant="standard"
                                                type="number"
                                                required
                                                value={formData.mobile}
                                                onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (formData.mobile.trim() === '') {
                                                            e.preventDefault();
                                                            toast.error('Please Enter Your Mobile No.');
                                                        } else {
                                                            setFocus("txtEmailID");
                                                        }
                                                    }
                                                }}
                                            />
                                            {formData.countryCode === '+91' && (
                                                <button
                                                    style={{ cursor: (formData.mobile.length !== 10 || isDisabled) ? 'not-allowed' : 'pointer' }}
                                                    className={`bg-primary text-white py-2 w-70 border-0 rounded mybtn mt-3 ms-2 ${formData.mobile.length !== 10 ? "disabledBTN" : "mybtn"}`}
                                                    onClick={HandleOTPSentMobile}
                                                    disabled={formData.mobile.length !== 10 || isDisabled} 
                                                >
                                                    Send OTP
                                                </button>
                                                
                                            )}
                                        </Box>
                                        {otpSentMobile && formData.countryCode === '+91' &&  (
                                            <div className='my-3 ms-2 '>
                                                <label>Enter 6 Digit OTP</label>
                                                <MuiOtpInput
                                                    value={formData.MobileOTP}
                                                    length={6}
                                                    onChange={(otp) => setFormData({ ...formData, MobileOTP: otp })}
                                                />
                                                {/*<p className='my-3 d-flex' >*/}
                                                {/*    Didn't receive an OTP?*/}
                                                {/*    <h6 onClick={HandleOTPSentMobile} disabled={isDisabled} style={{ fontWeight: 'bold', cursor: "pointer", marginLeft: "7px" }}>{isDisabled ? `Resend (${timer}s)` : 'Resend'}</h6>*/}
                                                {/*</p>*/}
                                                <p style={{}} className='my-3 d-flex'>
                                                    Didn't receive an OTP?
                                                    <button
                                                        type="button"
                                                        onClick={HandleOTPSentMobile}
                                                        style={{
                                                            background: 'none',
                                                            border: 'none',
                                                            color: '#007bff',
                                                            fontWeight: 'bold',
                                                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                            marginLeft: '7px',
                                                            opacity: isDisabled ? 0.5 : 1,
                                                        }}
                                                        disabled={isDisabled}
                                                    >
                                                        {isDisabled ? `Resend (${timer}s)` : 'Resend'}
                                                    </button>
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className='mb-2' >
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            sx={{ width: '100%' }}
                                            id="txtEmailID"
                                            label="Email"
                                            variant="standard"
                                            type="email"
                                            required
                                            value={formData.emailID}
                                            onChange={(e) => setFormData({ ...formData, emailID: e.target.value })}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (formData.emailID.trim() === '') {
                                                        e.preventDefault();
                                                        toast.error('Please Enter Your Email.');
                                                    } else {
                                                        setFocus("txtPassword");
                                                    }
                                                }
                                            }}
                                        />
                                        {formData.countryCode !== '+91' && (
                                            <button className='bg-primary text-white py-2 w-70 border-0 rounded mybtn mt-3 ms-2'
                                                onClick={HandleOTPSentEmail}
                                            >
                                                Send OTP
                                            </button>
                                            )}
                                        </Box>
                                        {otpSentEmail &&  formData.countryCode !== '+91'&& (
                                            <div className='my-3 ms-2'>
                                                <label>Enter 6 Digit OTP</label>
                                                <MuiOtpInput
                                                    value={formData.EmailOTP}
                                                    length={6}
                                                    onChange={(otp) => setFormData({ ...formData, EmailOTP: otp })}
                                                />
                                                <p className='my-3 d-flex' >
                                                    Didn't receive an OTP?
                                                    <h6 onClick={HandleOTPSentEmail} style={{ fontWeight: 'bold', cursor: "pointer", marginLeft: "7px" }}>Resend</h6>
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div className='mb-3' >
                                        <FormControl variant="standard" sx={{ width: '100%' }} required={true} >
                                            <InputLabel htmlFor="txtPassword">Password</InputLabel>
                                            <Input
                                                id="txtPassword"
                                                type={showPassword ? 'text' : 'password'}

                                                value={formData.password}
                                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        if (formData.password.trim() === '') {
                                                            e.preventDefault();
                                                            toast.error('Please Enter Your Password');
                                                        } else {
                                                            handleRegister();
                                                        }
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <ul style={{ listStyle: 'disc', padding: '5px 0 0px 25px' }}>
                                            <li className='text-black-50 small'>White spaces are not allowed.</li>
                                            <li className='text-black-50 small'>Password length should must be 8 to 15 character(s).</li>
                                            <li className='text-black-50 small'>Password must be combination of atleast one numeric and one upper case letter and one lower case letter  and special character [$#@!()].</li>
                                        </ul>
                                    </div>
                                    <div className='mt-3' >
                                        <button className='bg-primary text-white py-2 w-100 border-0 rounded mybtn' onClick={handleRegister}>Signup</button>
                                    </div>
                                </div>
                                <div className="login-footer text-center">
                                    <p>Already Have an Account? <Link to='/login' className='text-primary'>Login Now</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
